import React from 'react';
import { graphql } from 'gatsby';
import GallerySection from 'components/GallerySection';
import PartnersSection from 'components/PartnersSection';
import Layout from 'components/Layout';
import Seo from 'components/Seo';

const Gallery = ({ data }) => {
  const {
    datoCmsHomepage,
    allDatoCmsPartner,
    datoCmsGlobal,
    datoCmsGallery,
    datoCmsGettingInvolvedPage,
    datoCmsMainMenu,
  } = data;

  return (
    <Layout
      globalData={datoCmsGlobal}
      mainMenuData={datoCmsMainMenu}
      engagementKinds={datoCmsGettingInvolvedPage?.involvementKind}
    >
      <Seo
        title={datoCmsGallery?.metaTags?.title}
        description={datoCmsGallery?.metaTags?.description}
      />
      <GallerySection
        data={datoCmsGallery}
        title={datoCmsGallery?.galleryTitle}
        overTitle={datoCmsGallery?.galleryOvertitle}
        pics={datoCmsGallery?.pictures}
        variant="topSection"
      />
      <PartnersSection
        data={datoCmsHomepage}
        partners={allDatoCmsPartner?.edges ?? []}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    datoCmsHomepage {
      partnersOvertitle
      partnersTitle
    }

    datoCmsGlobal {
      facebookLink
      instagramLink
      linkedinLink
      mailContact
      twitterLink
      footerText
      terms
    }

    allDatoCmsPartner {
      edges {
        node {
          id
          name
          logo {
            url
            alt
            ... on DatoCmsFileField {
              gatsbyImageData
            }
          }
        }
      }
    }
    datoCmsGallery {
      metaTags {
        description
        title
      }
      galleryTitle
      galleryOvertitle
      pictures {
        isImage
        alt
        title
        url
        width
        height
        createdAt
        ... on DatoCmsFileField {
          gatsbyImageData
        }
      }
    }
    datoCmsGettingInvolvedPage {
      involvementKind {
        title
        description
        information
        cta
        ctaLink
        icon {
          url
        }
      }
    }
    datoCmsMainMenu {
      blogLink
      galleryLink
      homepageLink
      involvmentLink
    }
  }
`;

export default Gallery;
